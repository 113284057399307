import Button from '@mui/material/Button'
import Card from '@mui/material/Card'
import CardActions from '@mui/material/CardActions'
import CardContent from '@mui/material/CardContent'
import CardMedia from '@mui/material/CardMedia'
import Grid from '@mui/material/Grid'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import Container from '@mui/material/Container'
import React from 'react'
import ReactDOM from 'react-dom'
import NashHeader from './common/LandingHeader'
import Dotted from '../../static/images/Dotted.svg'
import image1 from '../../static/images/image1.svg'
import image2 from '../../static/images/image2.svg'
import image3 from '../../static/images/image3.svg'
import image4 from '../../static/images/image4.svg'
import Footer from './common/layout/Footer'
import learningAccelerator from '../../static/images/learningAccelerator.svg'
import bootStarterPortal from '../../static/images/bootStarterPortal.svg'
import knoldusLibrary from '../../static/images/knoldusLibrary.svg'
import benefitImage from '../../static/images/benefitImage.svg'
import CheckCircleOutlineRoundedIcon from '@mui/icons-material/CheckCircleOutlineRounded';
import "./LandingPage.scss"

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 500,
  height: 400,
  color: 'white',
  fontFamily: 'Mulish',
  fontStyle: "normal",
  borderRadius: '10px',
  boxShadow: 24,
  pt: 2,
  px: 4,
  pb: 3,
}

const cards = [
  {
    title: 'Learning Accelerators',
    description:
      'These are your one-step-solutions, providing curated templates in more than 22 technologies and languages. Our repository contains templates to accelerate all of your development requirements. Kick-start your development with these ready-to-deploy templates.',
    link: 'https://techhub.knoldus.com/dashboard',
    image: learningAccelerator
  },
  {
    title: 'Boot Starter Portal',
    description:
      'The Boot Starter Portal provides developers with pre-configured templates and tools for creating new applications in Java, Scala, and Kotlin. Developers can quickly get started building applications by selecting a template and configuring it to meet their specifications.',
    link: '/knoldus-boot-accelerator',
    image: bootStarterPortal
  },
  {
    title: 'Knoldus Libraries',
    description:
      'Reusable components binded as a library. These are pre-built software components designed to be easily integrated into new projects and to provide common functionality across applications.',
    link: '/knoldus-library',
    image: knoldusLibrary
  },
]

const benefits = [
  {
    title: 'By just simple clicking we can generate the template.',
    icon: image1,
  },
  {
    title: 'Consists of more than 22 technologies and languages.',
    icon: image2,
  },
  {
    title: 'Re-usable components are bonded as lib which we can integrate.',
    icon: image3,
  },
  {
    title:
      'Will save time rather than installing libraries or dependencies individually.',
    icon: image4,
  },
]
export default function LandingPage() {
  const routeChange = link => {
    window.open(link, '_blank')
  }

  return ReactDOM.createPortal(
    <Box>
      <NashHeader />
      <Box sx={{ display: "flex", flexDirection: "column", alignItems: "center", marginTop: "5rem"}}>
        <Box sx={{display:'flex'}}>
        <Typography sx={{
          fontWeight: "800",
          fontSize: "36px",
          fontFamily: 'Mulish',
          fontStyle: "normal",
          lineHeight: "45px",
          textAlign: "center",
          background: "linear-gradient(270deg, #D6001C -54.17%, #6D297B 100%)",
          backgroundClip: "text",
          textFillColor: "transparent",
          marginTop: "3rem",
          marginRight: "16px"
        }}>
          NashTech Accelerators 
        </Typography>
        <Typography sx={{
          fontWeight: "300",
          fontSize: "36px",
          fontFamily: 'Mulish',
          fontStyle: "normal",
          lineHeight: "45px",
          textAlign: "center",
          background: "linear-gradient(270deg, #D6001C -54.17%, #6D297B 100%)",
          backgroundClip: "text",
          textFillColor: "transparent",
          marginTop: "3rem"
        }}>
          speed up development
        </Typography>
        </Box>
        
        <Typography alignItems="center" alignContent="center" sx={{
          fontWeight: "400",
          width: "34%",
          height: "64px",
          fontSize: "18px",
          fontFamily: 'Mulish',
          fontStyle: "normal",
          lineHeight: "28px",
          textAlign: "center",
          color: "#000000",
          marginTop: "3rem"
        }}>
          Boosting digital platform, data platform and automation business solutions via the below predefined templates respectively.
        </Typography>
      </Box>
      <Container maxWidth="lg">
        <Grid container sx={{ py: 15, paddingTop: "90px", paddingBottom: "-2rem" }}>
          {cards.map(card => (
            <Grid className='grid-card' item xs={12} sm={6} md={4}
            >
              <Card
                className='card-hover'
                sx={{
                  backgroundColor: 'inherit',
                  height: '90%',
                  // position: 'relative',
                  width: '97%',
                  display: 'flex',
                  flexDirection: 'column',
                  boxShadow: 'none',
                  justifyContent: 'space-between',
                  color: "inherit"
                }}
              >
                <Typography
                  className='title'
                  gutterBottom
                  variant='h4'
                  component='div'
                  sx={{
                    marginBottom: "2rem",
                    fontWeight: "800",
                    fontSize: "20px",
                    lineHeight: "25px",
                    textAlign: "center",
                    color: "inherit",
                  }}
                >
                  {card.title}
                </Typography>
                <Box sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center"
                }}>
                  <CardMedia
                    component='img'
                    image={card.image}
                    alt='random'
                    sx={{ marginBottom: "1rem", width: "200px" }}
                  /></Box>
                <Box className='box-hover'>
                  <Typography
                    variant='body2'
                    sx={{
                      marginBottom: "2rem",
                      fontWeight: "400",
                      fontSize: "14px",
                      lineHeight: "24px",
                      color: "inherit",
                      minHeight: "170px"
                    }}
                  >
                    {card.description}
                  </Typography>
                  <CardActions disableSpacing sx={{ justifyContent: 'center', flexDirection: "column" }}>
                    <div className='button-hover' style={{ display: "flex", justifyContent: 'center', flexDirection: "column" }}>
                      <Button
                        className='button-explore'
                        size='large'
                        variant='outlined'
                        sx={{
                          boxSizing: "border-box",
                          display: "flex",
                          flexDirection: "row",
                          alignItems: "center",
                          padding: "12px 16px",
                          fontFamily: 'Mulish',
                          fontStyle: "normal",
                          gap: "10px",
                          width: "129px",
                          height: "40px",
                          border: "inherit",
                          borderRadius: "3px",
                          textTransform: 'none',
                          color: 'inherit',
                        }}
                        onClick={() => routeChange(card.link)}
                      >
                        Explore More
                      </Button>
                      <Box className='buttonFrame'>
                      </Box>
                    </div>
                  </CardActions>
                </Box>
                <Box className='frame1'>
                </Box>
                <Box className='frame2'></Box>
              </Card>
            </Grid>
          ))}
        </Grid>
      </Container>
      <Box sx={{
        marginBottom: '10rem',
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        padding: "0px",
        gap: "40px",
      }}>
        <Container maxWidth='lg'>
          <Typography
            component='h2'
            variant='h2'
            align='center'
            sx={{
              fontWeight: "800",
              fontFamily: 'Mulish',
              fontStyle: "normal",
              fontSize: "32px",
              lineHeight: "40px",
              color: "#28292B",
            }}
          >
            Benefits
          </Typography>
        </Container>
        <Container maxWidth='lg' sx={{
          display: "flex", flexDirection: "column",
          alignItems: "center"
        }}>
          <Typography
            component='h2'
            variant='h2'
            align='center'
            gutterBottom
            sx={{
              width: "63%",
              height: "220px",
              fontWeight: "400",
              fontFamily: 'Mulish',
              fontStyle: "normal",
              fontSize: "18px",
              lineHeight: "28px",
              color: "#000000",
            }}
          >
            <p style={{margin:"0px 1px"}}>
              Accelerate your development and learning with our repository{' '}
              <a href='https://techhub.knoldus.com/dashboard' style={{color:'inherit', fontWeight: 600}}>
                techhub.knoldus.com
              </a>.
            </p>
          
            With a Boot Starter Portal, developers can quickly get started building new applications by selecting a template and 
            configuring it to meet their specific needs, rather than having to set up a new project from scratch. This can save a 
            lot of time and effort, and allow developers to focus on building the core functionality of their application. Knoldus 
            own created lib can be used as re-usable components and save your effort on surfing web.
            
          </Typography>
          <Box sx={{
            display: "flex",
            flexDirection: "row",
            padding: "0px",
            backgroundImage: "linear-gradient(306.99deg,#d6001c -109.25%,#4d1f7a 65.82%)",
            borderRadius: "6px",
          }}>
            <Box sx={{
              backgroundColor: "rgb(255 255 255)", margin: "1px",
              borderRadius: "6px",
              display: "flex",
            }}>
              <Box sx={{ margin: "3rem" }}>
                <Box sx={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "center",
                  alignItems: "flex-start",
                  padding: "0px",
                  margin: "2rem"
                }}>
                  <Card
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                      alignItems: "flex-start",
                      padding: "0px 20px",
                      gap: "12px",
                      boxShadow: "none",
                    }}>
                    <Box sx={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      padding: "0px",
                      gap: "12px",
                      // width: "90px",
                      height: "38px",
                    }}>
                      <CheckCircleOutlineRoundedIcon color='success' />
                      <Typography sx={{
                        fontWeight: "700",
                        fontSize: "18px",
                        lineHeight: "38px",
                        background: "linear-gradient(270deg, #D6001C -54.17%, #6D297B 100%)",
                        backgroundClip: "text",
                        textFillColor: "transparent",
                        fontFamily: 'Mulish',
                        fontStyle: "normal",
                      }}>
                        Speed
                      </Typography>
                    </Box>
                    <Typography sx={{
                      fontWeight: "400",
                      fontSize: "16px",
                      lineHeight: "26px",
                      color: "#28292B",
                      fontFamily: 'Mulish',
                      fontStyle: "normal",
                    }}>
                      Will save time rather than installing libraries or dependencies individually.
                    </Typography>
                  </Card>
                  <Card
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                      alignItems: "flex-start",
                      padding: "0px 20px",
                      gap: "12px",
                      boxShadow: "none",
                    }}>
                    <Box sx={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      padding: "0px",
                      gap: "12px",
                      // width: "90px",
                      height: "38px",
                    }}>
                      <CheckCircleOutlineRoundedIcon color='success' />
                      <Typography sx={{
                        fontWeight: "700",
                        fontSize: "18px",
                        lineHeight: "38px",
                        background: "linear-gradient(270deg, #D6001C -54.17%, #6D297B 100%)",
                        backgroundClip: "text",
                        textFillColor: "transparent",
                        fontFamily: 'Mulish',
                        fontStyle: "normal",
                      }}>
                        Reusable
                      </Typography>
                    </Box>
                    <Typography sx={{
                      fontWeight: "400",
                      fontSize: "16px",
                      lineHeight: "26px",
                      color: "#28292B",
                      fontFamily: 'Mulish',
                      fontStyle: "normal",
                    }}>
                      Re-usable components are bonded as lib which we can integrate.
                    </Typography>
                  </Card>
                </Box>
                <Box sx={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "center",
                  alignItems: "flex-start",
                  padding: "0px",
                  margin: "2rem"
                }}>
                  <Card
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                      alignItems: "flex-start",
                      padding: "0px 20px",
                      gap: "12px",
                      boxShadow: "none",
                    }}>
                    <Box sx={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      padding: "0px",
                      gap: "12px",
                      // width: "90px",
                      height: "38px",
                    }}>
                      <CheckCircleOutlineRoundedIcon color='success' />
                      <Typography sx={{
                        fontWeight: "700",
                        fontSize: "18px",
                        lineHeight: "38px",
                        background: "linear-gradient(270deg, #D6001C -54.17%, #6D297B 100%)",
                        backgroundClip: "text",
                        textFillColor: "transparent",
                        fontFamily: 'Mulish',
                        fontStyle: "normal",
                      }}>
                        Scalable
                      </Typography>
                    </Box>
                    <Typography sx={{
                      fontWeight: "400",
                      fontSize: "16px",
                      lineHeight: "26px",
                      color: "#28292B",
                    }}>
                      Consists of more than 22 technologies and languages.
                    </Typography>
                  </Card>
                  <Card
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                      alignItems: "flex-start",
                      padding: "0px 20px",
                      gap: "12px",
                      boxShadow: "none",
                      width: "47%",
                    }}>
                    <Box sx={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      padding: "0px",
                      gap: "12px",
                      height: "38px",
                    }}>
                      <CheckCircleOutlineRoundedIcon color='success' />
                      <Typography sx={{
                        fontWeight: "700",
                        fontSize: "18px",
                        lineHeight: "38px",
                        background: "linear-gradient(270deg, #D6001C -54.17%, #6D297B 100%)",
                        backgroundClip: "text",
                        textFillColor: "transparent",
                        fontFamily: 'Mulish',
                        fontStyle: "normal",
                      }}>
                        Time Saver
                      </Typography>
                    </Box>
                    <Typography sx={{
                      fontWeight: "400",
                      fontSize: "16px",
                      lineHeight: "26px",
                      color: "#28292B",
                      fontFamily: 'Mulish',
                      fontStyle: "normal",
                    }}>
                      Will save time rather than installing libraries or dependencies individually.
                    </Typography>
                  </Card>
                </Box>
              </Box>
              <Box
                component="img"
                alt="benifit"
                src={benefitImage}
                sx={{ marginTop: "14px" }} />
            </Box>
          </Box>
        </Container>
      </Box >
      <Box />
      <Footer />
    </Box >,
    document.getElementById('landing')
  )
}
