import PropTypes from 'prop-types'
import get from 'lodash.get'
import React, { useContext, useState, useEffect } from 'react'
import Actions from './Actions'
import Control from './Control'
import FieldError from './FieldError'
import FieldInput from './FieldInput'
import FieldRadio from './FieldRadio'
import Warnings from './Warnings'
import useWindowsUtils from '../../utils/WindowsUtils'
import { AppContext } from '../../reducer/App'
import { Button, Radio } from '../form'
import { Dependency } from '../dependency'
import { InitializrContext } from '../../reducer/Initializr'
import Plugin from '../plugin/Plugin'
import set from 'lodash.set'

function Fields({
  onSubmit,
  onExplore,
  onShare,
  refExplore,
  refSubmit,
  refDependency,
  refplugin,
  generating,
  exploring,
}) {
  const windowsUtils = useWindowsUtils()
  const { config, dispatch, dependencies } = useContext(AppContext)
  const {
    values,
    dispatch: dispatchInitializr,
    errors,
  } = useContext(InitializrContext)
  const update = args => {
    dispatchInitializr({ type: 'UPDATE', payload: args })
  }
  const [required, setRequired] = useState(false);
  const buildTypeVersionList = get(config, 'lists.buildTypeVersions')
  let buildTypeVersionOptions = buildTypeVersionList.filter(list => {
    return list.key == get(values, 'buildType')
  })[0].text
  const languageVersionList = get(config, 'lists.languageVersions')
  let languageVersionOptions = languageVersionList.filter(list => {
    return list.key == get(values, 'language')
  })[0].text

  useEffect(() => {
    if (get(values, 'meta.name') === "" | get(values, 'meta.projectVersion') === "" | get(values, 'meta.description') === "" | get(values, 'meta.packageName') === "" | get(values, 'meta.userDetails') === "") {
      setRequired(true)
    }
    else setRequired(false)
  }, [get(values, 'meta.name'), get(values, 'meta.projectVersion'), get(values, 'meta.description'), get(values, 'meta.packageName'), get(values, 'meta.userDetails')])

  useEffect(() => {
    dispatch({
      type: 'UPDATE_DEPENDENCIES',
      payload: { boot: get(values, 'language'), languageVersion: get(values, 'languageVersion') },
    })
    dispatch({
      type: 'UPDATE_PLUGIN',
      payload: { boot: get(values, 'language'), languageVersion: get(values, 'languageVersion') },
    })
  }, [get(values, 'language'), get(values, 'languageVersion')])

  return (
    <>
      <div className='colset colset-main'>
        <div className='left'>
          <Warnings />
          <div className='col-sticky'>
            <div >
              {/* <div className='left'> */}
                <Control text='Build Type'>
                  <Radio
                    name='buildType'
                    selected={get(values, 'buildType')}
                    options={get(config, 'lists.buildType')}
                    onChange={value => {
                      const new_buildType = value
                      const new_buildTypeVersion = buildTypeVersionList.filter(list => {
                        return list.key == new_buildType
                      })[0].text
                      update({ buildType: new_buildType, buildTypeVersion: new_buildTypeVersion[0].key })
                    }}
                  />
                </Control>
              {/* </div> */}
              {/* <div className='right'> */}
                <Control text='Language'>
                  <Radio
                    name='language'
                    selected={get(values, 'language')}
                    options={get(config, 'lists.language')}
                    onChange={value => {
                      const new_language = value
                      const new_languageVersion = languageVersionList.filter(list => {
                        return list.key == new_language
                      })[0].text
                      update({ language: new_language, languageVersion: new_languageVersion[0].key })
                    }}
                  />
                </Control>
              {/* </div> */}
            </div>
            <Control text='BuildType Version'>
              <Radio
                name='buildTypeVersion'
                selected={get(values, 'buildTypeVersion')}
                options={buildTypeVersionOptions}
                onChange={value => {
                  update({ buildTypeVersion: value })
                }}
              />
            </Control>
            <div>
            <Control text='Language Version'>
              <Radio
                name='languageVersion'
                selected={get(values, 'languageVersion')}
                options={languageVersionOptions}
                onChange={value => {
                  update({ languageVersion: value })
                }}
              />
            </Control>
            </div>
            <Control text='Project Metadata'>
              <FieldInput
                id='input-name'
                value={get(values, 'meta.name')}
                text='Name'
                placeholder="demoproject"
                onChange={event => {
                  update({ meta: { name: event.target.value } })
                }}
              />
              <FieldInput
                id='input-projectVersion'
                value={get(values, 'meta.projectVersion')}
                text='Project Version'
                placeholder="1.1"
                onChange={event => {
                  update({ meta: { projectVersion: event.target.value } })
                }}
              />
              <FieldInput
                id='input-description'
                value={get(values, 'meta.description')}
                text='Description'
                placeholder="Description"
                onChange={event => {
                  update({ meta: { description: event.target.value } })
                }}
              />
              <FieldInput
                id='input-packageName'
                value={get(values, 'meta.packageName')}
                text='Package name'
                placeholder="PackageName"
                onChange={event => {
                  update({ meta: { packageName: event.target.value } })
                }}
              />
              <FieldInput
                id='input-userDetails'
                value={get(values, 'meta.userDetails')}
                text='User Details'
                placeholder="demo@knoldus.com"
                onChange={event => {
                  update({ meta: { userDetails: event.target.value } })
                }}
              />
            </Control>
          </div>
        </div>
        <div className='right'>
          <Dependency refButton={refDependency} />
          <Plugin refButton={refplugin} />
        </div>
      </div>
      <Actions>
        {generating ? (
          <span className='placeholder-button placeholder-button-submit placeholder-button-special'>
            Generating...
          </span>
        ) :
          (
            <Button
              id='generate-project'
              variant='primary'
              onClick={onSubmit}
              hotkey={`${windowsUtils.symb} + ⏎`}
              refButton={refSubmit}
              disabled={generating | required}
            >
              Generate
            </Button>
          )}
        {exploring ? (
          <span className='placeholder-button placeholder-button-submit placeholder-button-special'>
            Exploring...
          </span>
        ) :
          (
            <Button
              id='explore-project'
              onClick={onExplore}
              hotkey='Ctrl + Space'
              refButton={refExplore}
              disabled={exploring | required}
            >
              Explore
            </Button>
          )}
        {/* <Button id='share-project' onClick={onShare}>
          Share...
        </Button> */}
      </Actions>
    </>
  )
}

Fields.propTypes = {
  generating: PropTypes.bool.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onExplore: PropTypes.func.isRequired,
  onShare: PropTypes.func.isRequired,
  refExplore: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({ current: PropTypes.instanceOf(Element) }),
  ]).isRequired,
  refSubmit: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({ current: PropTypes.instanceOf(Element) }),
  ]).isRequired,
  refDependency: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({ current: PropTypes.instanceOf(Element) }),
  ]).isRequired,
  refPlugin: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({ current: PropTypes.instanceOf(Element) }),
  ]).isRequired,
}

export default Fields
