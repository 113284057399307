import BodyClassName from 'react-body-classname'
import FileSaver from 'file-saver'
import get from 'lodash.get'
import React, {
  Suspense,
  lazy,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react'
import { toast } from 'react-toastify'

import useHash from './utils/Hash'
import useWindowsUtils from './utils/WindowsUtils'
import { AppContext } from './reducer/App'
import { DependencyDialog } from './common/dependency'
import { PluginDialog } from './common/plugin'
import { Fields, Loading } from './common/builder'
import { Form } from './common/form'
import { Header, SideLeft, SideRight } from './common/layout'
import { InitializrContext } from './reducer/Initializr'
import {getConfig, getInfo, getProject } from './utils/ApiUtils'

const Explore = lazy(() => import('./common/explore/Explore'))
const Share = lazy(() => import('./common/share/Share'))
const HotKeys = lazy(() => import('./common/builder/HotKeys'))

export default function Application() {
  const {
    complete,
    dispatch,
    theme,
    share: shareOpen,
    explore: exploreOpen,
    list,
    pluginList,
    dependencies,
  } = useContext(AppContext)
  const {
    values,
    share,
    dispatch: dispatchInitializr,
  } = useContext(InitializrContext)
  const [blob, setBlob] = useState(null)
  const [generating, setGenerating] = useState(false)
  const [exploring, setExploring] = useState(false)
  const buttonExplore = useRef(null)
  const buttonDependency = useRef(null)
  const buttonPlugin = useRef(null)
  const buttonSubmit = useRef(null)
  const windowsUtils = useWindowsUtils()
  useHash()

  useEffect(() => {
    const buildTypeUrl = "https://accelerator-backend.prod.go1percent.com/rest/get/supported_builds_versions"
    const languageUrl = "https://accelerator-backend.prod.go1percent.com/rest/get/language_versions"
    const dependencyUrl = 'https://accelerator-backend.prod.go1percent.com/rest/get/dependencies'
    const pluginUrl = 'https://accelerator-backend.prod.go1percent.com/rest/get/plugins'
    getInfo(buildTypeUrl).then(jsonConfig => {
      getInfo(languageUrl).then(jsonConfig1 => {
        getInfo(dependencyUrl).then(jsonConfig2 => {
          getInfo(pluginUrl).then(jsonConfig3 => {
            jsonConfig = { ...jsonConfig, ...jsonConfig1.data, ...jsonConfig2.data, ...jsonConfig3.data }
            const response = getConfig(jsonConfig)
            dispatchInitializr({ type: 'COMPLETE', payload: { ...response } })
            dispatch({ type: 'COMPLETE', payload: response })
          })
        })
      })
    })
  }, [dispatch, dispatchInitializr, windowsUtils.origin])

  const onSubmit = async () => {
    if (generating || list || pluginList) {
      return
    }
    setGenerating(true)
    const url = `https://accelerator-backend.prod.go1percent.com/rest/request/project`
    const project = await getProject(url,values)
    .catch((error)=>{ 
      toast.error(`${error}`)
      setGenerating(false)
    })
    setGenerating(false)
    if (project) {
      FileSaver.saveAs(project, `${get(values, 'meta.name')}.zip`)
  }}

  const onExplore = async () => {
    setExploring(true)
    const url = `https://accelerator-backend.prod.go1percent.com/rest/request/project`
    const project = await getProject(
      url,
      values,
      get(dependencies, 'list'),
    ).catch((error) => {
      toast.error(`${error}`)
    })
    setExploring(false)
    if(project){
    setBlob(project)
    dispatch({ type: 'UPDATE', payload: { explore: true, list: false, pluginList:false } })
    }
  }

  const onShare = () => {
    dispatch({ type: 'UPDATE', payload: { share: true } })
  }

  const onEscape = () => {
    setBlob(null)
    dispatch({
      type: 'UPDATE',
      payload: { list: false, share: false, explore: false, nav: false, pluginList: false },
    })
  }

  return (
    <div>
      <BodyClassName className={theme} />
      <Suspense fallback=''>
        <HotKeys
          onSubmit={() => {
            if (get(buttonSubmit, 'current')) {
              buttonSubmit.current.click()
            }
          }}
          onExplore={() => {
            if (get(buttonExplore, 'current')) {
              buttonExplore.current.click()
            }
          }}
          onDependency={event => {
            if (get(buttonDependency, 'current')) {
              buttonDependency.current.click()
            }
            event.preventDefault()
          }}
          onPlugin={event => {
            if (get(buttonPlugin, 'current')) {
              buttonPlugin.current.click()
            }
            event.preventDefault()
          }}
          onEscape={onEscape}
        />
      </Suspense>
      <SideLeft />
      <div id='main'>
        <Header />
        <hr className='divider' style={{margin: "0.2rem 23px 1rem"}} />
        <div id= "description" style={{textAlign: "left", marginLeft: "2%", marginRight: "2%",font: "normal normal normal 18px/34px Poppins",letterSpacing: "0px",opacity: 1}}>
        The Boot Starter Portal provides developers with pre-configured templates and tools for creating new applications in Java, Scala, and Kotlin. Developers can quickly get started building applications by selecting a template and configuring it to meet their specifications.
        </div>
        {/* <hr className='divider' /> */}
        <Form onSubmit={onSubmit}>
          {!complete ? (
            <Loading />
          ) : (
            <>
              <Fields
                onSubmit={onSubmit}
                onShare={onShare}
                onExplore={onExplore}
                refExplore={buttonExplore}
                refSubmit={buttonSubmit}
                refDependency={buttonDependency}
                refPlugin={buttonPlugin}
                generating={generating}
                exploring={exploring}
              />
              <DependencyDialog onClose={onEscape} />
              <PluginDialog onClose={onEscape} />
            </>
          )}
        </Form>
      </div>
      <SideRight />
      <Suspense fallback=''>
        <Share open={shareOpen || false} shareUrl={share} onClose={onEscape} />
        <Explore
          projectName={`${get(values, 'meta.name')}.zip`}
          blob={blob}
          open={exploreOpen}
          onClose={onEscape}
        />
      </Suspense>
    </div>
  )
}
