import './styles/app.scss'
import React from 'react'
import { ToastContainer } from 'react-toastify'
import { render } from 'react-dom'
import { BrowserRouter } from "react-router-dom";
import Application from './components/Application'
import Close from './components/common/form/Close'
import { Route, Routes } from 'react-router-dom';
import { AppProvider } from './components/reducer/App'
import { InitializrProvider } from './components/reducer/Initializr'
import LandingPage from './components/LandingPage'
import KnoldusLibrary from './components/KnoldusLibrary';

render(
  <BrowserRouter>
  <AppProvider>
    <InitializrProvider>
    <ToastContainer
        closeButton={<Close />}
        position='top-center'
        hideProgressBar
        style={{width: "500px", marginTop: "100px"}}
      />
      <Routes>
        <Route path='/' element={<LandingPage />} />
        <Route path='/knoldus-boot-accelerator' element= {<Application/>} />
        <Route path='/knoldus-library' element= {<KnoldusLibrary/>} />
      </Routes>
    </InitializrProvider>
  </AppProvider>
  </BrowserRouter>,
  document.getElementById('app')
)
