import React, { useContext } from 'react'

import LogoMobile from './LogoMobile'
import { AppContext } from '../../reducer/App'
import { IconMoon, IconSun } from '../icons'
import logoDark from '../../../../static/images/whitelogo.svg'
import logoLight from '../../../../static/images/bluelogo.svg'
import {Button} from "../form";

function HeaderMobile() {
  const { nav, theme, dispatch } = useContext(AppContext)
  const logo = theme == 'dark' ? logoDark : logoLight
  return (
    <div className='is-mobile header-mobile'>
      <h1 className='logo logo-mobile'>
          <div style={{ display: "flex", alignItems: "center" }}>
              <a href='/knoldus-boot-accelerator' style={{ width: "500px", height: "50px" }} >
            <span className='logo-content' tabIndex='-1'>
              <img width="80px" height="70px" src={logo}></img>
            </span>
          </a>
              <a href='/knoldus-boot-accelerator' style={{ width: "500px", height: "15px" }} >
            <span style={{fontSize: '15px', width: '446px'}} className='logo-content' tabIndex='-1'>
              <div>Boot Accelerators Portal</div>
            </span>
          </a>
              <a
                  style={{ textDecoration: 'none' }}
                  href='https://accelerator.prod.go1percent.com/'
              >
                  <Button id='back'>&lt;</Button>
              </a>
        </div>
      </h1>
      <div className='switch-mobile'>
        <button
          type='button'
          aria-label='Switch theme'
          aria-controls='navigation'
          onClick={e => {
            e.preventDefault()
            dispatch({
              type: 'UPDATE',
              payload: {
                theme: theme === 'dark' ? 'light' : 'dark',
              },
            })
          }}
        >
          {theme === 'dark' ? <IconSun /> : <IconMoon />}
        </button>
      </div>
      {/* <button
        className={`hamburger hamburger--spin ${nav ? 'is-active' : ''}`}
        type='button'
        aria-label='Menu'
        aria-controls='navigation'
        onClick={() => {
          dispatch({ type: 'UPDATE', payload: { nav: !nav } })
        }}
      >
        <span className='hamburger-box' tabIndex='-1'>
          <span className='hamburger-inner' />
        </span>
      </button> */}
    </div>
  )
}

export default HeaderMobile
