import get from 'lodash.get'
import querystring from 'querystring'
import set from 'lodash.set'
import Extend from '../../Extend.json'
import { isInRange, parseReleases, parseVersion } from './Version'

const PROPERTIES_MAPPING_URL = {
  buildTypetype: 'buildType',
  language: 'language',
  buildTypeVersion: 'buildTypeVersion',
  languageVersion: 'languageVersion',
  name: 'meta.name',
  projectVersion: 'meta.projectVersion',
  description: 'meta.description',
  packageName: 'meta.packageName',
  dependencies: 'dependencies',
  plugin: 'plugin',
  userDetails: 'meta.userDetails'

}

export const getInfo = function getInfo(url) {
  return new Promise((resolve, reject) => {
    fetch(`${url}`, {
      method: 'GET',
      headers: {
        Accept: '*/*',
      },
    })
      .then(
        response => response.json(),
        () => {
          reject()
          return null
        }
      )
      .then(data => {
        if (data) {
          resolve(data)
        }
      })
  })
}

export const getShareUrl = values => {
  const props = {}
  Object.keys(PROPERTIES_MAPPING_URL).forEach(key => {
    const key2 = get(PROPERTIES_MAPPING_URL, key)
    const value = get(values, key2)
    if (key !== 'dependencies') {
      set(props, key, value)
    }
  })
  let params = `${querystring.stringify(props)}`
  if (get(values, 'dependencies', []).length > 0) {
    params = `${params}&dependencies=${get(values, 'dependencies').join(',')}`
  }
  return params
}

export const isValidParams = params => {
  return (
    Object.keys(params)
      .map(entry => {
        return !!get(PROPERTIES_MAPPING_URL, entry, null)
      })
      .filter(item => !!item).length > 0
  )
}

export const parseParams = (values, queryParams, lists) => {
  const errors = {}
  const warnings = {}
  if (isValidParams(queryParams)) {
    Object.keys(queryParams).forEach(entry => {
      const key = get(PROPERTIES_MAPPING_URL, entry)
      if (key) {
        const value = get(queryParams, entry, '').toLowerCase()
        switch (key) {
          case 'buildType':
          case 'language':
          case 'meta.packaging':
          case 'meta.java': {
            const list = get(lists, key, [])
            const res = list.find(a => a.key.toLowerCase() === value)
            if (res) {
              set(values, key, res.key)
            } else {
              const currentValue = list.find(
                a => a.key.toLowerCase() === get(values, key)
              )
              set(warnings, key, {
                value: get(queryParams, entry, ''),
                select: currentValue.text,
              })
            }
            break
          }
          case 'boot': {
            const list = get(lists, key, [])
            const res = list.find(a => a.key.toLowerCase() === value)
            let error = false
            if (res) {
              set(values, key, res.key)
            } else {
              error = true
              let versionMajor = value
              if (versionMajor.indexOf('.x') === -1) {
                versionMajor = get(parseVersion(versionMajor), 'major', '')
              }
              if (versionMajor.indexOf('.x') > -1) {
                const releases = parseReleases(list).filter(
                  release =>
                    release.major.toLowerCase() === versionMajor.toLowerCase()
                )
                if (releases.length > 0) {
                  const release = releases.reduce((p, c) => {
                    if (p.qualify > c.qualify) {
                      return p
                    }
                    if (p.qualify === c.qualify) {
                      if (p.minor > c.minor) {
                        return p
                      }
                    }
                    return c
                  }, releases[0])

                  if (release) {
                    error = false
                    set(values, key, release.version)
                    const currentValue = list.find(
                      a => a.key.toLowerCase() === release.version.toLowerCase()
                    )
                    set(warnings, key, {
                      value: get(queryParams, entry, ''),
                      select: currentValue.text,
                    })
                  }
                }
              }
            }
            if (error) {
              set(errors, 'boot', {
                value: get(queryParams, entry, ''),
              })
            }
            break
          }
          case 'dependencies': {
            const depsWarning = []
            const newVal = value
              .split(',')
              .map(item => {
                const dep = get(lists, 'dependencies').find(
                  d => d.id === item.trim()
                )
                if (dep) {
                  return dep.id
                }
                depsWarning.push(item)
                return null
              })
              .filter(item => !!item)

            if (depsWarning.length > 0) {
              set(warnings, key, {
                value: depsWarning.join(', '),
              })
            }
            set(values, key, newVal)
            break
          }
          default:
            set(values, key, get(queryParams, entry, ''))
        }
      }
    })
  }
  return {
    values,
    errors,
    warnings,
  }
}

export const getLists = json => {
  const deps = []
  get(json, 'depedencies', []).map(item => {
    const extend = Extend.find(it => it.id === get(item, 'id', ''))
    const val = {
      id: `${get(item, 'id', '')}`,
      name: `${get(item, 'name', '')}`,
      group: `${item.categories}`,
      description: `${get(item, 'desc', '')}`,
      language: `${get(item, 'language', '')}`,
      languageVersions: get(item, 'languageVersions', []),
      fullName: `${get(item, 'fullName', '')}`,
      isTestLib: `${get(item, 'isTestLib', '')}`,
      tags: `${get(item, 'tags', [])}`,
      dependencyType: `${get(item, 'dependencyType')}`,
      resolver: `${get(item, 'resolver')}`,
      scope: `${get(item, 'scope')}`,
    }
    deps.push(val)
  })
  const plugs = []
  get(json, 'plugin', []).map(item => {
    const extend = Extend.find(it => it.id === get(item, 'id', ''))
    const val = {
      id: `${get(item, 'id', '')}`,
      name: `${get(item, 'name', '')}`,
      group: `${item.categories}`,
      description: `${get(item, 'desc', '')}`,
      versionRange: `${get(item, 'version', '')}`,
      languageVersions: get(item, 'languageVersions', []),
      fullName: `${get(item, 'fullName', '')}`,
      isKnoldusPlugin: `${get(item, 'isKnoldusPlugin', '')}`,
      language: `${get(item, 'language', '')}`,
      tags: `${get(item, 'tags', [])}`,
      weight: get(extend, 'weight', 50),
    }
    plugs.push(val)
  })

  return {
    buildType: get(json, 'data.allBuildsVersions', [])
      .map(type => ({
        key: `${type.build}`,
        text: `${type.build}`,
      })),
    language: get(json, 'allSupLanguageVersions', [])
      .map(language => ({
        key: `${language.language}`,
        text: `${language.language}`,
      })),
    buildTypeVersions: get(json, 'data.allBuildsVersions', []).map(boot => ({
      key: `${boot.build}`,
      text: [...boot.versions].map(version => ({
        key: `${version}`,
        text: `${version}`
      })),
    })),
    languageVersions: get(json, 'allSupLanguageVersions', []).map(language => ({
      key: `${language.language}`,
      text: [...language.versions].map(version => ({
        key: `${version}`,
        text: `${version}`
      })),
    })),
    dependencies: deps,
    plugin: plugs,
  }
}

export const getDefaultValues = json => {
  return {
    buildType: "sbt",
    language: "scala",
    buildTypeVersion: "1.3.8",
    languageVersion: "2.11.12",
    meta: {
      name: "",
      projectVersion: "",
      description: "",
      packageName: "",
      userDetails: ""
    },
    dependencies: [],
    plugin: [],
  }
}

export const getConfig = json => {
  return {
    lists: getLists(json),
    defaultValues: getDefaultValues(json),
  }
}

export const getDependencyConfig = json => {
  return {
    lists: getDependencyLists(json),
    defaultValues: getDefaultValues(json),
  }
}

export const getPluginConfig = json => {
  return {
    lists: getPluginLists(json),
    defaultValues: getDefaultValues(json),
  }
}

export const isValidDependency = function isValidDependency(boot, dependency,languageVersion) {
  if (!dependency) {
    return false
  }
  // console.log(dependency.languageVersions,dependency, String(parseFloat(languageVersion)))
    if(dependency.language==boot && dependency.languageVersions.find(item=> item.startsWith(String(parseFloat(languageVersion)))))
    return true
    else
    return false
}

const b64toBlob = (b64Data, contentType='', sliceSize=512) => {
  const byteCharacters = atob(b64Data);
  const byteArrays = [];
  for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
    const slice = byteCharacters.slice(offset, offset + sliceSize);
    const byteNumbers = new Array(slice.length);
    for (let i = 0; i < slice.length; i++) {
      byteNumbers[i] = slice.charCodeAt(i);
    }
    const byteArray = new Uint8Array(byteNumbers);
    byteArrays.push(byteArray);
  }
  const blob = new Blob(byteArrays, {type: contentType});
  return blob;
}

export const getProject = function getProject(url, values) {
  return new Promise((resolve, reject) => {
    const params = JSON.stringify({
      buildType: get(values, 'buildType'),
      language: get(values, 'language'),
      languageVersion: get(values, 'languageVersion'),
      buildVersion: get(values, 'buildTypeVersion'),
      projectName: get(values, 'meta.name'),
      projectVersion: get(values, 'meta.projectVersion'),
      description: get(values, 'meta.description'),
      packageName: get(values, 'meta.packageName'),
      userDetails: {
        emailId: get(values, 'meta.userDetails'),
      },
      dependencies: get(values, 'dependencies', []).filter(dependency=>{
        return isValidDependency(get(values, 'language'),dependency,get(values, 'languageVersion'))
      }).map(dependency => ({
        name: `${dependency.name}`,
        fullName: `${dependency.fullName}`,
        isTestLib: `${dependency.isTestLib}` === "true" ? true : false,
        dependencyType: `${dependency.dependencyType}`,
        scope: `${dependency.scope}`,
        resolver: `${dependency.resolver}`
      })),
      plugins: get(values, 'plugin', []).filter(plugin=>{
        return isValidDependency(get(values, 'language'),plugin,get(values, 'languageVersion'))
      }).map(plugin => ({
        name: `${plugin.name}`,
        fullName: `${plugin.fullName}`,
        version: `${plugin.version}`
      })),
    })
    if (params) {
      fetch(`${url}`, {
        method: 'POST',
        body: params,
        headers: {
          Accept: '*/*',
          'Content-Type': 'application/json',
        },
      })
        .then(
          response => response.json(),
          () => {
            reject()
          }
        )
        .then(data => {
          if (data && data.status !== "failed") {
            const blob = b64toBlob(data.data, 'application/zip')
            resolve(blob)
          }
          else reject(new Error(data.errors.message))
        },
        () => {
          reject()
        })
    }
  }
  )
}
