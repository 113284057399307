import React, { useContext } from 'react'
import HeaderMobile from './HeaderMobile'
import Logo from './Logo'
import logoDark from '../../../../static/images/whitelogo.svg'
import logoLight from '../../../../static/images/bluelogo.svg'
import { AppContext } from '../../reducer/App'

function Header() {
  const { theme } = useContext(AppContext)
  const logo = theme == 'dark' ? logoDark : logoLight
  return (
    <header id='header'>
      <div className='not-mobile'>
        <h1 className='logo'>
          <div style={{ display: "flex", alignItems: "center" }}>
            <a href='/knoldus-boot-accelerator' style={{
              width: "500px", height: "19px",
              marginLeft: "1.65rem",
              marginTop: "1rem"
            }} >
              <span style={{ width: '500px' }} className='logo-content' tabIndex='-1'>
                <div style={{ display: 'flex', flexDirection: 'row' }}><div style={{ color: '#007bff', marginRight: '0.75rem' }}>Boot</div><div> Accelerators Portal</div></div>
              </span>
            </a>
          </div>
        </h1>
      </div >
      <HeaderMobile />
    </header >
  )
}

export default Header
