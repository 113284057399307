import { Container, Typography, Box, Modal, Button, Drawer } from "@mui/material"
import React, { useState } from "react"
import NashHeader from './common/LandingHeader'
import Footer from './common/layout/Footer'
import Card from '@mui/material/Card'
import CardActions from '@mui/material/CardActions'
import CardMedia from '@mui/material/CardMedia'
import Grid from '@mui/material/Grid'
import awsMarkdown from './markdown/awsReadme.md'
import ReactMarkdown from "react-markdown"
import styled from "@emotion/styled"
import IconButton from '@mui/material/IconButton';
import ReactDOM from 'react-dom'
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter'
import { dark } from 'react-syntax-highlighter/dist/esm/styles/prism'
import CloseIcon from '@mui/icons-material/Close'
import loggingMarkdown from './markdown/loggingReadme.md'
import cipherMarkdown from './markdown/cipherReadme.md'
import "./LandingPage.scss"
import loggingLibrary from '../../static/images/loggingLibrary.svg'
import cipherLibrary from '../../static/images/cipherLibrary.svg'
import knoldusAwsLibrary from '../../static/images/knoldusAwsLibrary.svg'



const cards = [
    {
        title: 'Knoldus AWS Library',
        image: knoldusAwsLibrary,
        description: "A Scala library that makes it easy for developers to connect and interact with some popular AWS services without worrying about the connection logic.",
    },

]

const cardsCipher = [
    {
        title: 'Cipher Library',
        image: cipherLibrary,
        description: "This library can be used to encrypt and decrypt messages with support to custom attributes also.",

    },
]

const cardsLogging = [
    {
        title: 'Logging Library',
        image: loggingLibrary,
        description: "Logging helps us to monitor the activity of our application, specially in case if any error occurs we can check it from the logs and fix it. This library uses Rollbar for logging and tracing.",

    },
]

const DrawerHeader = styled('div')(() => ({
    alignItems: 'center',
    justifyContent: 'flex-start',
}));

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 500,
    height: 400,
    color: 'white',
    bgcolor: '#485EB6',
    borderRadius: '10px',
    boxShadow: 24,
    pt: 2,
    px: 4,
    pb: 3,
}

export default function KnoldusLibrary() {
    const [drawerOpen, setDrawerOpen] = useState(false);
    const [open, setOpen] = React.useState(false)
    const [openModal, setOpenModal] = React.useState(false)
    const [loggingDrawerOpen, setLoggingDrawerOpen] = useState(false)
    const [cipherDrawerOpen, setCipherDrawerOpen] = useState(false)
    const handleOpen = () => setOpen(true)
    const handleClose = () => setOpen(false)
    const modalOpen = () => setOpenModal(true)
    const modalClose = () => setOpenModal(false)

    return ReactDOM.createPortal(
        <>
            <NashHeader />
            <Modal
                open={openModal}
                onClose={modalClose}
                aria-labelledby='modal-modal-title'
                aria-describedby='modal-modal-description'
            >
                <Box sx={{ ...style }}>
                    <Typography
                        id='modal-modal-description'
                        sx={{
                            mt: 2,
                            fontSize: '2rem',
                            fontFamily: 'Mulish',
                            fontStyle: 'normal',
                            fontWeight: '400',
                        }}

                    >
                        <img
                            style={{
                                display: 'block',
                                maxWidth: '100%',
                                height: 'auto',
                                marginLeft: 'auto',
                                marginRight: 'auto',
                                width: '50%',
                            }}
                            src='https://i.postimg.cc/VLbd8Xq3/79cdbd490251e750e5afce048532dec0.jpg'
                            alt='text'
                            width='200'
                            height='200'
                        />
                        <p>Live Demo Coming Soon!</p>
                        <a src="https://github.com/knoldus/knoldus_aws_lib_example"></a>
                    </Typography>
                </Box>
            </Modal>
            <Drawer
                sx={{
                    flexShrink: 0,
                    '& .MuiDrawer-paper': {
                        width: "40%",
                        paddingLeft: 2
                    },
                }}
                variant="persistent"
                anchor="right"
                open={drawerOpen}
            >
                <DrawerHeader>
                    <IconButton onClick={() => { setDrawerOpen(false) }}>
                        <CloseIcon fontSize="large" />
                    </IconButton>
                    <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                        <Box sx={{ display: 'flex', alignItems: '' }}>
                            <Typography variant="h4" sx={{
                                fontWeight: 'bold', fontFamily: 'Mulish',
                                fontStyle: 'normal',
                                fontWeight: '800',
                            }}>
                                Knoldus AWS Library
                            </Typography>
                            <Button onClick={modalOpen} size='small'
                                variant='outlined'
                                sx={{
                                    borderRadius: '6px',
                                    width: '20%',
                                    marginLeft: 2,
                                    // marginTop: '-21px',
                                    marginBottom: '1rem',
                                    backgroundColor: '#0A192C',
                                    color: '#ffff',
                                    fontFamily: 'Mulish',
                                    fontStyle: 'normal',
                                    fontWeight: '400',
                                    ':hover': { color: 'black' },
                                }}>
                                View Demo
                            </Button>
                        </Box>

                        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                            <Typography variant="p" sx={{
                                fontFamily: 'Mulish',
                                fontStyle: 'normal',
                                fontWeight: '400',
                            }}>
                                Sample example on how to use the knoldus AWS Library
                            </Typography>
                            <a href="https://github.com/knoldus/knoldus_aws_lib_example" target="_blank">Sample AWS Library example</a>
                        </Box>
                    </Box>
                    <ReactMarkdown components={{
                        code({ node, inline, className, children, ...props }) {
                            const match = /language-(\w+)/.exec(className || '')
                            return !inline && match ? (
                                <SyntaxHighlighter
                                    children={String(children).replace(/\n$/, '')}
                                    style={dark}
                                    language={match[1]}
                                    PreTag="div"
                                    {...props}
                                />
                            ) : (
                                <code className={className} {...props}>
                                    {children}
                                </code>
                            )
                        }
                    }}>
                        {awsMarkdown}
                    </ReactMarkdown>
                </DrawerHeader>
            </Drawer>
            <Drawer
                sx={{
                    flexShrink: 0,
                    '& .MuiDrawer-paper': {
                        width: "40%",
                        paddingLeft: 2
                    },
                }}
                variant="persistent"
                anchor="right"
                open={loggingDrawerOpen}
            >
                <DrawerHeader>
                    <IconButton onClick={() => { setLoggingDrawerOpen(false) }}>
                        <CloseIcon fontSize="large" />
                    </IconButton>
                    <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                        <Box sx={{ display: 'flex', alignItems: '' }}>
                            <Typography variant="h4" sx={{
                                fontWeight: 'bold', fontFamily: 'Mulish',
                                fontStyle: 'normal',
                                fontWeight: '800',
                            }}>
                                Logging Library
                            </Typography>
                            <Button onClick={modalOpen} size='small'
                                variant='outlined'
                                sx={{
                                    borderRadius: '6px',
                                    width: '20%',
                                    marginLeft: 2,
                                    marginBottom: '1rem',
                                    backgroundColor: '#0A192C',
                                    color: '#ffff',
                                    fontFamily: 'Mulish',
                                    fontStyle: 'normal',
                                    fontWeight: '400',
                                    ':hover': { color: 'black' },
                                }}>
                                View Demo
                            </Button>
                        </Box>
                        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                            <Typography variant="p" sx={{
                                fontFamily: 'Mulish',
                                fontStyle: 'normal',
                                fontWeight: '400',
                            }}>
                                Sample example on how to use the Logging Library
                            </Typography>
                            <a href=" https://github.com/knoldus/knoldus_logging_lib_example" target="_blank">Sample Logging Library example  </a>
                        </Box>
                    </Box>
                    <ReactMarkdown components={{
                        code({ node, inline, className, children, ...props }) {
                            const match = /language-(\w+)/.exec(className || '')
                            return !inline && match ? (
                                <SyntaxHighlighter
                                    children={String(children).replace(/\n$/, '')}
                                    style={dark}
                                    language={match[1]}
                                    PreTag="div"
                                    {...props}
                                />
                            ) : (
                                <code className={className} {...props}>
                                    {children}
                                </code>
                            )
                        }
                    }}>
                        {loggingMarkdown}
                    </ReactMarkdown>
                </DrawerHeader>
            </Drawer>
            <Drawer
                sx={{
                    flexShrink: 0,
                    '& .MuiDrawer-paper': {
                        width: "40%",
                        paddingLeft: 2
                    },
                }}
                variant="persistent"
                anchor="right"
                open={cipherDrawerOpen}
            >
                <DrawerHeader>
                    <IconButton onClick={() => { setCipherDrawerOpen(false) }}>
                        <CloseIcon fontSize="large" />
                    </IconButton>
                    <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                        <Box sx={{ display: 'flex', alignItems: '' }}>
                            <Typography variant="h4" sx={{
                                fontWeight: 'bold', fontFamily: 'Mulish',
                                fontStyle: 'normal',
                                fontWeight: '800',
                            }}>
                                Cipher Library
                            </Typography>
                            <Button onClick={modalOpen} size='small'
                                variant='outlined'
                                sx={{
                                    borderRadius: '6px',
                                    width: '20%',
                                    marginLeft: 2,
                                    marginBottom: '1rem',
                                    backgroundColor: '#0A192C',
                                    color: '#ffff',
                                    fontFamily: 'Mulish',
                                    fontStyle: 'normal',
                                    fontWeight: '400',
                                    ':hover': { color: 'black' },
                                }}>
                                View Demo
                            </Button>
                        </Box>
                        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                            <Typography variant="p" sx={{
                                fontFamily: 'Mulish',
                                fontStyle: 'normal',
                                fontWeight: '400',
                            }}>
                                Sample example on how to use the Cipher Library
                            </Typography>
                            <a href="https://github.com/knoldus/cipher-lib" target="_blank">Sample Cipher Library example  </a>
                        </Box>
                    </Box>
                    <ReactMarkdown components={{
                        code({ node, inline, className, children, ...props }) {
                            const match = /language-(\w+)/.exec(className || '')
                            return !inline && match ? (
                                <SyntaxHighlighter
                                    children={String(children).replace(/\n$/, '')}
                                    style={dark}
                                    language={match[1]}
                                    PreTag="div"
                                    {...props}
                                />
                            ) : (
                                <code className={className} {...props}>
                                    {children}
                                </code>
                            )
                        }
                    }}>
                        {cipherMarkdown}
                    </ReactMarkdown>
                </DrawerHeader>
            </Drawer>
            <Drawer
                sx={{
                    flexShrink: 0,
                    '& .MuiDrawer-paper': {
                        width: "40%",
                        paddingLeft: 2
                    },
                }}
                variant="persistent"
                anchor="right"
                open={cipherDrawerOpen}
            >
                <DrawerHeader>
                    <IconButton onClick={() => { setCipherDrawerOpen(false) }}>
                        <CloseIcon fontSize="large" />
                    </IconButton>
                    <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                        <Box sx={{ display: 'flex', alignItems: '' }}>
                            <Typography variant="h4" sx={{ fontWeight: 'bold' }}>
                                Cipher Library
                            </Typography>
                            <Button onClick={modalOpen} size='small
                    '
                                variant='outlined'
                                sx={{
                                    borderRadius: '6px',
                                    width: '20%',
                                    marginLeft: 2,
                                    marginBottom: '1rem',
                                    backgroundColor: '#0A192C',
                                    color: '#ffff',
                                    ':hover': { color: 'black' },
                                }}>
                                View Demo
                            </Button>
                        </Box>
                        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                            <Typography variant="p">
                                Sample example on how to use the Cipher Library
                            </Typography>
                            <a href="https://github.com/knoldus/cipher-lib" target="_blank">Sample Cipher Library example  </a>
                        </Box>
                    </Box>
                    <ReactMarkdown components={{
                        code({ node, inline, className, children, ...props }) {
                            const match = /language-(\w+)/.exec(className || '')
                            return !inline && match ? (
                                <SyntaxHighlighter
                                    children={String(children).replace(/\n$/, '')}
                                    style={dark}
                                    language={match[1]}
                                    PreTag="div"
                                    {...props}
                                />
                            ) : (
                                <code className={className} {...props}>
                                    {children}
                                </code>
                            )
                        }
                    }}>
                        {cipherMarkdown}
                    </ReactMarkdown>
                </DrawerHeader>
            </Drawer>
            <Box sx={{ display: "flex", flexDirection: "column", alignItems: "center", marginTop: "5rem" }}>
                <Typography sx={{
                    fontWeight: "800",
                    fontSize: "36px",
                    fontFamily: 'Mulish',
                    fontStyle: "normal",
                    lineHeight: "45px",
                    textAlign: "center",
                    background: "linear-gradient(270deg, #D6001C -54.17%, #6D297B 100%)",
                    backgroundClip: "text",
                    textFillColor: "transparent",
                    marginTop: "3rem"
                }}>
                    Knoldus Library
                </Typography>
            </Box>
            <Container maxWidth="lg">
                <Grid container spacing={3} sx={{ py: 15, paddingTop: "90px", paddingBottom: "80px" }}>
                    {/* <Grid container spacing={4} sx={{ py: 10, px: 25, marginTop: '5rem', marginBottom: '12rem' }} > */}
                    {cards.map(card => (
                        <Grid className='grid-card' item xs={12} sm={6} md={4}
                        >
                            <Card
                                className='card-hover'
                                sx={{
                                    backgroundColor: 'inherit',
                                    height: '90%',
                                    position: 'relative',
                                    width: '97%',
                                    display: 'flex',
                                    flexDirection: 'column',
                                    boxShadow: 'none',
                                    justifyContent: 'space-between',
                                    color: "inherit"
                                }}
                            >
                                <Typography
                                    className='title'
                                    gutterBottom
                                    variant='h4'
                                    component='div'
                                    sx={{
                                        marginBottom: "2rem",
                                        fontWeight: "800",
                                        fontSize: "20px",
                                        lineHeight: "25px",
                                        textAlign: "center",
                                        color: "inherit",
                                    }}
                                >
                                    {card.title}
                                </Typography>
                                <Box sx={{
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center"
                                }}>
                                    <CardMedia
                                        component='img'
                                        image={card.image}
                                        alt='random'
                                        sx={{ marginBottom: "1rem", width: "200px" }}
                                    /></Box>
                                <Box className='box-hover'>
                                    <Typography
                                        variant='body2'
                                        sx={{
                                            // marginBottom: "2rem",
                                            fontWeight: "400",
                                            fontSize: "14px",
                                            lineHeight: "24px",
                                            color: "inherit",
                                            minHeight: "170px"
                                        }}
                                    >
                                        {card.description}
                                    </Typography>
                                    <CardActions disableSpacing sx={{ justifyContent: 'center', flexDirection: "column" }}>
                                        <div className='button-hover' style={{ display: "flex", justifyContent: 'center', flexDirection: "column" }}>
                                            <Button
                                                className='button-explore'
                                                size='large'
                                                variant='outlined'
                                                sx={{
                                                    boxSizing: "border-box",
                                                    display: "flex",
                                                    flexDirection: "row",
                                                    alignItems: "center",
                                                    padding: "12px 16px",
                                                    gap: "10px",
                                                    width: "129px",
                                                    height: "40px",
                                                    border: "inherit",
                                                    borderRadius: "3px",
                                                    textTransform: 'none',
                                                    color: 'inherit',
                                                }}
                                                onClick={() => { setDrawerOpen(true) }}
                                            >
                                                Explore More
                                            </Button>
                                            <Box className='buttonFrame'>
                                            </Box>
                                        </div>
                                    </CardActions>
                                </Box>
                                <Box className='frame1'>
                                </Box>
                                <Box className='frame2'></Box>
                            </Card>
                        </Grid>
                    ))}
                    {cardsLogging.map(card => (
                        <Grid className='grid-card' item xs={12} sm={6} md={4}
                        >
                            <Card
                                className='card-hover'
                                sx={{
                                    backgroundColor: 'inherit',
                                    height: '90%',
                                    position: 'relative',
                                    width: '97%',
                                    display: 'flex',
                                    flexDirection: 'column',
                                    boxShadow: 'none',
                                    justifyContent: 'space-between',
                                    color: "inherit"
                                }}
                            >
                                <Typography
                                    className='title'
                                    gutterBottom
                                    variant='h4'
                                    component='div'
                                    sx={{
                                        marginBottom: "2rem",
                                        fontWeight: "800",
                                        fontSize: "20px",
                                        lineHeight: "25px",
                                        textAlign: "center",
                                        color: "inherit",
                                    }}
                                >
                                    {card.title}
                                </Typography>
                                <Box sx={{
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center"
                                }}>
                                    <CardMedia
                                        component='img'
                                        image={card.image}
                                        alt='random'
                                        sx={{ marginBottom: "1rem", width: "200px" }}
                                    /></Box>
                                <Box className='box-hover'>
                                    <Typography
                                        variant='body2'
                                        sx={{
                                            // marginBottom: "2rem",
                                            fontWeight: "400",
                                            fontSize: "14px",
                                            lineHeight: "24px",
                                            color: "inherit",
                                            minHeight: "170px"
                                        }}
                                    >
                                        {card.description}
                                    </Typography>
                                    <CardActions disableSpacing sx={{ justifyContent: 'center', flexDirection: "column" }}>
                                        <div className='button-hover' style={{ display: "flex", justifyContent: 'center', flexDirection: "column" }}>
                                            <Button
                                                className='button-explore'
                                                size='large'
                                                variant='outlined'
                                                sx={{
                                                    boxSizing: "border-box",
                                                    display: "flex",
                                                    flexDirection: "row",
                                                    alignItems: "center",
                                                    padding: "12px 16px",
                                                    gap: "10px",
                                                    width: "129px",
                                                    height: "40px",
                                                    border: "inherit",
                                                    borderRadius: "3px",
                                                    textTransform: 'none',
                                                    color: 'inherit',
                                                }}
                                                onClick={() => { setLoggingDrawerOpen(true) }}
                                            >
                                                Explore More
                                            </Button>
                                            <Box className='buttonFrame'>
                                            </Box>
                                        </div>
                                    </CardActions>
                                </Box>
                                <Box className='frame1'>
                                </Box>
                                <Box className='frame2'></Box>
                            </Card>
                        </Grid>
                    ))}
                    {cardsCipher.map(card => (
                        <Grid className='grid-card' item xs={12} sm={6} md={4}
                        >
                            <Card
                                className='card-hover'
                                sx={{
                                    backgroundColor: 'inherit',
                                    height: '90%',
                                    position: 'relative',
                                    width: '97%',
                                    display: 'flex',
                                    flexDirection: 'column',
                                    boxShadow: 'none',
                                    justifyContent: 'space-between',
                                    color: "inherit"
                                }}
                            >
                                <Typography
                                    className='title'
                                    gutterBottom
                                    variant='h4'
                                    component='div'
                                    sx={{
                                        marginBottom: "2rem",
                                        fontWeight: "800",
                                        fontSize: "20px",
                                        lineHeight: "25px",
                                        textAlign: "center",
                                        color: "inherit",
                                    }}
                                >
                                    {card.title}
                                </Typography>
                                <Box sx={{
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center"
                                }}>
                                    <CardMedia
                                        component='img'
                                        image={card.image}
                                        alt='random'
                                        sx={{ marginBottom: "1rem", width: "200px" }}
                                    /></Box>
                                <Box className='box-hover'>
                                    <Typography
                                        variant='body2'
                                        sx={{
                                            // marginBottom: "2rem",
                                            fontWeight: "400",
                                            fontSize: "14px",
                                            lineHeight: "24px",
                                            color: "inherit",
                                            minHeight: "170px"
                                        }}
                                    >
                                        {card.description}
                                    </Typography>
                                    <CardActions disableSpacing sx={{ justifyContent: 'center', flexDirection: "column" }}>
                                        <div className='button-hover' style={{ display: "flex", justifyContent: 'center', flexDirection: "column" }}>
                                            <Button
                                                className='button-explore'
                                                size='large'
                                                variant='outlined'
                                                sx={{
                                                    boxSizing: "border-box",
                                                    display: "flex",
                                                    flexDirection: "row",
                                                    alignItems: "center",
                                                    padding: "12px 16px",
                                                    gap: "10px",
                                                    width: "129px",
                                                    height: "40px",
                                                    border: "inherit",
                                                    borderRadius: "3px",
                                                    textTransform: 'none',
                                                    color: 'inherit',
                                                }}
                                                onClick={() => { setCipherDrawerOpen(true) }}
                                            >
                                                Explore More
                                            </Button>
                                            <Box className='buttonFrame'>
                                            </Box>
                                        </div>
                                    </CardActions>
                                </Box>
                                <Box className='frame1'>
                                </Box>
                                <Box className='frame2'></Box>
                            </Card>
                        </Grid>
                    ))}
                </Grid>
            </Container>
            <Footer />
        </>, document.getElementById('library')

    )
}
